<template>
    <div>
        <div class="r-option-place r-center-flex r-isWrap">
            <InputCustom
                :rightIcon="true"
                :withError="false"
                v-model="request.search" 
                @keyup="setTime()" 
                @keydown="clearTime()" 
                @keydown.tab="setTime()" 
                @keyup.tab="clearTime()" 
                @keypress.enter="getData()" 
                placeholder="Search . . ."
                size='md'
                >
                <template #rightIcon>
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require('@/assets/icons/others/search.svg')" alt="search">
                    </div>
                </template>
            </InputCustom>

            <ButtonCustom
                class="r-ml-auto"
                @click="openModal()"
                :disabled="isFull"
            >
                Tambah Data
            </ButtonCustom>
        </div>

        <TableCustom 
            :columns="columns" 
            :request="request" 
            :fetchData="fetchData"
            :data="data"
            @setSortBy="setSortBy"
            @setShow="setShow" 
            @getData="getData"
            @mouseleave="popup = null"
            @togglePopup="togglePopup"
        >
            <template #index="props">
                {{curNumber(props.index)}}
            </template>

            <template #name="props">
                {{props.row.name}}
            </template>

            <template #action="props">
                <div class="r-menu">
                    <img class="r-is-clickable" :src="require('@/assets/icons/others/more.svg')" @click.prevent="popup = curNumber(props.index)">
                    <div class="r-menu-items" v-if="popup == curNumber(props.index)" @mouseleave="popup = -1">
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="setData(props.row)">Ubah</div>
                        <div class="r-menu-item y-red-text r-center-flex"  @click.prevent="openDeleteModal(props.row)">Hapus</div>
                    </div>
                </div>
            </template>
        </TableCustom>

        <InputModal 
            v-if="modal == 'inputModal'" 
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        ></InputModal>

        <DeleteModal
            v-if="modal == 'deleteModal'" 
            @resetData="resetData"
            @getData="getData"
        >
        </DeleteModal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import InputModal from '@/components/Ip/InputModal'
import DeleteModal from '@/components/Ip/DeleteModal'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {
        InputModal,
        DeleteModal
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            data: 'ip/getData',
            request: 'ip/getRequest',
        }),
        isFull() {
            return this.data?.data?.length == 5 ? true : false
        }
    },
    data: () => ({
        columns: [
            {
                label: 'No',
                isSortable: false,
                fieldName: 'index',
                class: 'r-table-firstColumn'
            },
            {
                label: 'IP',
                isSortable: true,
                fieldName: 'name',
                class: ''
            },
            {
                label: 'Action',
                isSortable: false,
                fieldName: 'action',
                class: 'r-table-actionColumn'
            }
        ],
        modal: null,
        inputType: 'Tambah',
    }),
    methods: {
        ...mapActions ({
            resetItem: 'ip/resetItem',
            resetRequest: 'ip/resetRequest',
            setItem: 'ip/setItem',
            getIps: 'ip/fetchData',
        }),
        openModal(type = 'Tambah') {
            this.modal = 'inputModal'
            this.inputType = type
        },
        setData(item) {
            this.setItem(item)
            this.openModal('Ubah')
        },
        openDeleteModal(item) {
            this.setItem(item)
            this.modal = 'deleteModal'
        },
        resetData() {
            this.modal = null
            this.resetItem()
            this.resetRequest()
        },
        async getData(type = 0) {
            this.fetchData = true
            if (type === 0) {
                this.request.page = 1
            } else {
                this.request.page += type
            }
            await this.getIps()
            this.fetchData = false
            const response = this.getResponse('ip')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async initData() {
            const breadcrumb = [{
                name: 'Ip',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Setting Ip')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
        },
    }
}
</script>