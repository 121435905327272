<template>
    <Modal label="Data Bank">
        <InputCustom
            v-model="item.name"
            :error="error.name"
            :errorMsg="errorMsg.name"
            @onFocus="resetError('name')"
            placeholder="IP"
            label="IP"
        >
        
        </InputCustom>
        
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                {{inputType}}
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
import validation from '@/mixins/function/validation'
export default {
    mixins: [
        common,
        validation
    ],
    components: {
        Modal,
    },
    mounted() {
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            item: 'ip/getItem'
        })
    },
    data: () => ({
        error: {
            name: null,
        },
        errorMsg: {
            name: 'Tidak boleh kosong',
        },
        loading: false,
    }),
    methods: {
        ...mapActions({
            store: 'ip/store',
            update: 'ip/update',
        }),
        resetData() {
            this.$emit('resetData')
        },
        validation() {
            var error = 0
            var field = ['name']
            for (var i = 0; i < field.length; i++){
                if(this.item[field[i]] === null || this.item[field[i]]  === ''){
                    this.error[field[i]] = true
                    error += 1;
                }else{
                    this.error[field[i]] = false
                }
            }
            return error
        },
        async sendData() {
            if (this.validation() === 0) {
                this.loading = true
                if (this.inputType == 'Tambah') {
                    await this.store(this.item)
                } else {
                    await this.update(this.item)
                }
                this.loading = false
                const response = this.getResponse('ip')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        }
    }
}
</script>